import styled from '../../../utils/styling/styled';

import Link from '../../Link';
import styleUtils from '../../../utils/styling/styleUtils';

export const TextLink = styled(Link)`
  ${styleUtils.animation.navigationListItem};

  color: ${props => props.theme.color.white};
`;
