import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const StaticPageSection = ({ children, ...rest }: Props) => {
  return <S.StaticPageSection {...rest}>{children}</S.StaticPageSection>;
};

export default StaticPageSection;
