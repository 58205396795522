import React from 'react';

import SectionStaticPageContent from '../Section/SectionStaticPageContent';
import StaticPageAddress from '../StaticPageItems/StaticPageAddress';
import StaticPageHeadline from '../StaticPageItems/StaticPageHeadline';
import StaticPageLanguageLink from '../StaticPageItems/StaticPageLanguageLink';
import StaticPageList from '../StaticPageItems/StaticPageList';
import StaticPageSection from '../StaticPageItems/StaticPageSection';
import StaticPageText from '../StaticPageItems/StaticPageText';
import StaticPageTextLink from '../StaticPageItems/StaticPageTextLink';
import StaticPageTitle from '../StaticPageItems/StaticPageTitle';
import routes from '../../config/routes';
import useGoogleAnalyticsOptOut from '../../utils/hooks/useGoogleAnalyticsOptOut';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  footer: ReactNode;
};

const PrivacyPolicyEnPage = ({ footer }: Props) => {
  const { handleGoogleAnalyticsOptOut, isGoogleAnalyticsOptOutSet } = useGoogleAnalyticsOptOut();

  return (
    <>
      <SectionStaticPageContent isTitleSection={true} contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={25}>
        <StaticPageTitle>
          Privacy
          <br />
          Policy
        </StaticPageTitle>

        <StaticPageLanguageLink url={routes.privacyPolicyDe}>German Version: Datenschutzerklärung</StaticPageLanguageLink>

        <StaticPageLanguageLink url={routes.privacyPolicyEn}>English Version: Privacy Policy</StaticPageLanguageLink>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={1}>Privacy Policy</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={2}>1. An overview of data protection</StaticPageHeadline>

            <StaticPageSection>
              <StaticPageHeadline level={3}>General</StaticPageHeadline>

              <StaticPageText>
                The following gives a simple overview of what happens to your personal information when you visit our website. Personal
                information is any data with which you could be personally identified. Detailed information on the subject of data
                protection can be found in our privacy policy found below.
              </StaticPageText>
            </StaticPageSection>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Data collection on our website</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={4}>Who is responsible for the data collection on this website?</StaticPageHeadline>

            <StaticPageText>
              The data collected on this website are processed by the website operator. The operator&#39;s contact details can be found in
              the website&#39;s required legal notice.
            </StaticPageText>
          </StaticPageSection>

          <StaticPageSection>
            <StaticPageHeadline level={4}>How do we collect your data?</StaticPageHeadline>

            <StaticPageText>
              Some data are collected when you provide it to us. This could, for example, be data you enter on a contact form.
            </StaticPageText>

            <StaticPageText>
              Other data are collected automatically by our IT systems when you visit the website. These data are primarily technical data
              such as the browser and operating system you are using or when you accessed the page. These data are collected automatically
              as soon as you enter our website.
            </StaticPageText>
          </StaticPageSection>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>What do we use your data for?</StaticPageHeadline>

          <StaticPageText>
            Part of the data is collected to ensure the proper functioning of the website. Other data can be used to analyze how visitors
            use the site.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>What rights do you have regarding your data?</StaticPageHeadline>

          <StaticPageText>
            You always have the right to request information about your stored data, its origin, its recipients, and the purpose of its
            collection at no charge. You also have the right to request that it be corrected, blocked, or deleted. You can contact us at any
            time using the address given in the legal notice if you have further questions about the issue of privacy and data protection.
            You may also, of course, file a complaint with the competent regulatory authorities.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Analytics and third-party tools</StaticPageHeadline>

          <StaticPageText>
            When visiting our website, statistical analyses may be made of your surfing behavior. This happens primarily using cookies and
            analytics. The analysis of your surfing behavior is usually anonymous, i.e. we will not be able to identify you from this data.
            You can object to this analysis or prevent it by not using certain tools. Detailed information can be found in the following
            privacy policy.
          </StaticPageText>
          <StaticPageText>
            You can object to this analysis. We will inform you below about how to exercise your options in this regard.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>2. General information and mandatory information</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Data protection</StaticPageHeadline>

            <StaticPageText>
              The operators of this website take the protection of your personal data very seriously. We treat your personal data as
              confidential and in accordance with the statutory data protection regulations and this privacy policy.
            </StaticPageText>

            <StaticPageText>
              If you use this website, various pieces of personal data will be collected. Personal information is any data with which you
              could be personally identified. This privacy policy explains what information we collect and what we use it for. It also
              explains how and for what purpose this happens.
            </StaticPageText>

            <StaticPageText>
              Please note that data transmitted via the internet (e.g. via email communication) may be subject to security breaches.
              Complete protection of your data from third-party access is not possible.
            </StaticPageText>
          </StaticPageSection>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Notice concerning the party responsible for this website</StaticPageHeadline>

            <StaticPageText>The party responsible for processing data on this website is:</StaticPageText>

            <StaticPageAddress>
              Jan Kath Design GmbH
              <br />
              Friederikastr. 148
              <br />
              44789 Bochum
              <br />
              Telephone: +49 234 9412344
              <br />
              E-Mail: <StaticPageTextLink url="mailto:info@jan-kath.com">info@jan-kath.com</StaticPageTextLink>
            </StaticPageAddress>

            <StaticPageText>
              The responsible party is the natural or legal person who alone or jointly with others decides on the purposes and means of
              processing personal data (names, email addresses, etc.).
            </StaticPageText>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start wrap" contentPositionY="flex-start wrap" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Revocation of your consent to the processing of your data</StaticPageHeadline>

          <StaticPageText>
            Many data processing operations are only possible with your express consent. You may revoke your consent at any time with future
            effect. An informal email making this request is sufficient. The data processed before we receive your request may still be
            legally processed.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Right to file complaints with regulatory authorities</StaticPageHeadline>

          <StaticPageText>
            If there has been a breach of data protection legislation, the person affected may file a complaint with the competent
            regulatory authorities. The competent regulatory authority for matters related to data protection legislation is the data
            protection officer of the German state in which our company is headquartered. A list of data protection officers and their
            contact details can be found at the following link:{' '}
            <S.ComplaintLink url="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
            </S.ComplaintLink>
            .
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Right to data portability</StaticPageHeadline>

          <StaticPageText>
            You have the right to have data which we process based on your consent or in fulfillment of a contract automatically delivered
            to yourself or to a third party in a standard, machine-readable format. If you require the direct transfer of data to another
            responsible party, this will only be done to the extent technically feasible.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>SSL or TLS Encryption</StaticPageHeadline>

          <StaticPageText>
            This site uses SSL or TLS encryption for security reasons and for the protection of the transmission of confidential content,
            such as the inquiries you send to us as the site operator. You can recognize an encrypted connection in your browser&#39;s
            address line when it changes from &#34;http://&#34; to &#34;https://&#34; and the lock icon is displayed in your browser&#39;s
            address bar.
          </StaticPageText>

          <StaticPageText>
            If SSL or TLS encryption is activated, the data you transfer to us cannot be read by third parties.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Information, blocking, deletion</StaticPageHeadline>

          <StaticPageText>
            As permitted by law, you have the right to be provided at any time with information free of charge about any of your personal
            data that is stored as well as its origin, the recipient and the purpose for which it has been processed. You also have the
            right to have this data corrected, blocked or deleted. You can contact us at any time using the address given in our legal
            notice if you have further questions on the topic of personal data.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Opposition to promotional emails</StaticPageHeadline>

          <StaticPageText>
            We hereby expressly prohibit the use of contact data published in the context of website legal notice requirements with regard
            to sending promotional and informational materials not expressly requested. The website operator reserves the right to take
            specific legal action if unsolicited advertising material, such as email spam, is received.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>3. Data protection officer</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Statutory data protection officer</StaticPageHeadline>

            <StaticPageText>We have appointed a data protection officer for our company.</StaticPageText>

            <StaticPageAddress>
              Jan Kath Design GmbH
              <br />
              Charlotte Kath
              <br />
              Friederikastr. 148
              <br />
              44789 Bochum
              <br />
              Telephone: +49 234 9412344
              <br />
              E-Mail: <StaticPageTextLink url="mailto:datenschutz@jan-kath.com">datenschutz@jan-kath.com</StaticPageTextLink>
            </StaticPageAddress>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>4. Data collection on our website</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Cookies</StaticPageHeadline>

            <StaticPageText>
              Some of our web pages use cookies. Cookies do not harm your computer and do not contain any viruses. Cookies help make our
              website more user-friendly, efficient, and secure. Cookies are small text files that are stored on your computer and saved by
              your browser.
            </StaticPageText>

            <StaticPageText>
              Most of the cookies we use are so-called &#34;session cookies.&#34; They are automatically deleted after your visit. Other
              cookies remain in your device&#39;s memory until you delete them. These cookies make it possible to recognize your browser
              when you next visit the site.
            </StaticPageText>

            <StaticPageText>
              You can configure your browser to inform you about the use of cookies so that you can decide on a case-by-case basis whether
              to accept or reject a cookie. Alternatively, your browser can be configured to automatically accept cookies under certain
              conditions or to always reject them, or to automatically delete cookies when closing your browser. Disabling cookies may limit
              the functionality of this website.
            </StaticPageText>

            <StaticPageText>
              Cookies which are necessary to allow electronic communications or to provide certain functions you wish to use (such as the
              shopping cart) are stored pursuant to Art. 6 paragraph 1, letter f of DSGVO. The website operator has a legitimate interest in
              the storage of cookies to ensure an optimized service provided free of technical errors. If other cookies (such as those used
              to analyze your surfing behavior) are also stored, they will be treated separately in this privacy policy.
            </StaticPageText>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Server log files</StaticPageHeadline>

          <StaticPageText>
            The website provider automatically collects and stores information that your browser automatically transmits to us in
            &#34;server log files&#34;. These are:
          </StaticPageText>

          <StaticPageList>
            <li>Browser type and browser version</li>
            <li>Operating system used</li>
            <li>Referrer URL</li>
            <li>Host name of the accessing computer</li>
            <li>Time of the server request</li>
            <li>IP address</li>
          </StaticPageList>

          <StaticPageText>These data will not be combined with data from other sources.</StaticPageText>

          <StaticPageText>
            The basis for data processing is Art. 6 (1) (f) DSGVO, which allows the processing of data to fulfill a contract or for measures
            preliminary to a contract.
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Registration on this website</StaticPageHeadline>

          <StaticPageText>
            You can register on our website in order to access additional functions offered here. The input data will only be used for the
            purpose of using the respective site or service for which you have registered. The mandatory information requested during
            registration must be provided in full. Otherwise, we will reject your registration.
          </StaticPageText>

          <StaticPageText>
            To inform you about important changes such as those within the scope of our site or technical changes, we will use the email
            address specified during registration.
          </StaticPageText>

          <StaticPageText>
            We will process the data provided during registration only based on your consent per Art. 6 (1)(a) DSGVO. You may revoke your
            consent at any time with future effect. An informal email making this request is sufficient. The data processed before we
            receive your request may still be legally processed.
          </StaticPageText>

          <StaticPageText>
            We will continue to store the data collected during registration for as long as you remain registered on our website. Statutory
            retention periods remain unaffected.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={50}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>5. Analytics and advertising</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Google Analytics</StaticPageHeadline>

            <StaticPageSection>
              <StaticPageText>
                This website uses Google Analytics, a web analytics service. It is operated by Google Inc., 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA.
              </StaticPageText>

              <StaticPageText>
                Google Analytics uses so-called &#34;cookies&#34;. These are text files that are stored on your computer and that allow an
                analysis of the use of the website by you. The information generated by the cookie about your use of this website is usually
                transmitted to a Google server in the USA and stored there.
              </StaticPageText>

              <StaticPageText>
                Google Analytics cookies are stored based on Art. 6 (1) (f) DSGVO. The website operator has a legitimate interest in
                analyzing user behavior to optimize both its website and its advertising.
              </StaticPageText>
            </StaticPageSection>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={4}>Browser plugin</StaticPageHeadline>

          <StaticPageText>
            You can prevent these cookies being stored by selecting the appropriate settings in your browser. However, we wish to point out
            that doing so may mean you will not be able to enjoy the full functionality of this website. You can also prevent the data
            generated by cookies about your use of the website (incl. your IP address) from being passed to Google, and the processing of
            these data by Google, by downloading and installing the browser plugin available at the following link:{' '}
            <StaticPageTextLink url="https://tools.google.com/dlpage/gaoptout?hl=en">
              https://tools.google.com/dlpage/gaoptout?hl=en
            </StaticPageTextLink>
            .
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Objecting to the collection of data</StaticPageHeadline>

          <StaticPageText>
            You can prevent the collection of your data by Google Analytics by clicking on the following link. An opt-out cookie will be set
            to prevent your data from being collected on future visits to this site:{' '}
            <button onClick={handleGoogleAnalyticsOptOut} type="button">
              Disable Google Analytics {isGoogleAnalyticsOptOutSet && '✔'}
            </button>
            .
          </StaticPageText>

          <StaticPageText>
            For more information about how Google Analytics handles user data, see Google&#39;s privacy policy:{' '}
            <StaticPageTextLink url="https://support.google.com/analytics/answer/6004245?hl=en">
              https://support.google.com/analytics/answer/6004245?hl=en
            </StaticPageTextLink>
            .
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Outsourced data processing</StaticPageHeadline>

          <StaticPageText>
            We have entered into an agreement with Google for the outsourcing of our data processing and fully implement the strict
            requirements of the German data protection authorities when using Google Analytics.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={2}>6. Plugins und Tools</StaticPageHeadline>

          <StaticPageSection>
            <StaticPageHeadline level={3}>Google Maps</StaticPageHeadline>

            <StaticPageSection>
              <StaticPageText>
                This site uses the Google Maps map service via an API. It is operated by Google Inc., 1600 Amphitheatre Parkway, Mountain
                View, CA 94043, USA.
              </StaticPageText>

              <StaticPageText>
                To use Google Maps, it is necessary to save your IP address. This information is generally transmitted to a Google server in
                the USA and stored there. The provider of this site has no influence on this data transfer.
              </StaticPageText>

              <StaticPageText>
                The use of Google Maps is in the interest of making our website appealing and to facilitate the location of places specified
                by us on the website. This constitutes a justified interest pursuant to Art. 6 (1) (f) DSGVO.
              </StaticPageText>

              <StaticPageText>
                Further information about handling user data, can be found in the data protection declaration of Google at:{' '}
                <StaticPageTextLink url="https://www.google.de/intl/de/policies/privacy/">
                  https://www.google.de/intl/de/policies/privacy/
                </StaticPageTextLink>
                .
              </StaticPageText>
            </StaticPageSection>
          </StaticPageSection>
        </StaticPageSection>
      </SectionStaticPageContent>

      {footer}
    </>
  );
};

export default PrivacyPolicyEnPage;
