import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  url: string;
} & HTMLAttributes<HTMLAnchorElement>;

const StaticPageLanguageLink = ({ children, url, ...rest }: Props) => {
  return (
    <S.LanguageLink url={url} {...rest}>
      {children}
    </S.LanguageLink>
  );
};

export default StaticPageLanguageLink;
