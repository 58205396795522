import React from 'react';

import theme from '../../../utils/styling/theme';

import * as S from './styles';

// Types
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  isHiddenOnMobile?: boolean;
  sectionWidth: SectionWidth;
  isTitleSection?: boolean;
};

const SectionStaticPageContent = ({
  children,
  contentPositionX,
  contentPositionY,
  isHiddenOnMobile = false,
  sectionWidth,
  isTitleSection = false,
}: Props) => {
  return (
    <S.Section
      backgroundColor={theme.color.grey}
      backgroundImage={null}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      isHiddenOnMobile={isHiddenOnMobile}
      sectionWidth={sectionWidth}
      isTitleSection={isTitleSection}
    >
      {children}
    </S.Section>
  );
};

export default SectionStaticPageContent;
