import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  url: string;
} & HTMLAttributes<HTMLAnchorElement>;

const StaticPageTextLink = ({ children, url, ...rest }: Props) => {
  return (
    <S.TextLink url={url} {...rest}>
      {children}
    </S.TextLink>
  );
};

export default StaticPageTextLink;
