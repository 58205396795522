import React from 'react';

import LayoutContainer from '../containers/Layout';
import PrivacyPolicyEnPageContainer from '../containers/PrivacyPolicyEnPage';

const PrivacyPolicyEnPagePage = () => {
  return (
    <LayoutContainer>
      <PrivacyPolicyEnPageContainer />
    </LayoutContainer>
  );
};

export default PrivacyPolicyEnPagePage;
