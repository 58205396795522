import styled from '../../../utils/styling/styled';

import styleUtils from '../../../utils/styling/styleUtils';

export const List = styled.ul`
  ${styleUtils.margin.vertical.bottomQuarter};

  color: ${props => props.theme.color.white};

  li {
    list-style-type: disc;
  }
`;
