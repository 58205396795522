import React from 'react';

import Heading from '../../components/Heading';
import PrivacyPolicyEnPage from '../../components/PrivacyPolicyEnPage';
import PrivacyPolicyEnPageBreadcrumb from '../../utils/seo/PrivacyPolicyEnPageBreadcrumb';
import SEOContainer from '../Layout/SEO';
import Section from '../../components/Heading/Section';
import SectionFooterContactDefaultContainer from '../Section/SectionFooterContactDefault';
import buildContentPageUrl from '../../utils/url/buildContentPageUrl';
import routes from '../../config/routes';
import theme from '../../utils/styling/theme';

// Types

type Props = {};

const PrivacyPolicyEnPageContainer = (_props: Props) => {
  const canonicalUrl = buildContentPageUrl(routes.privacyPolicyEn);

  const footer = <SectionFooterContactDefaultContainer backgroundColor={theme.color.grey} textColor={theme.color.white} />;

  return (
    <>
      {/* SEO */}
      <PrivacyPolicyEnPageBreadcrumb />
      <SEOContainer canonicalUrl={canonicalUrl} title="Privacy Policy" openGraphTitle="Privacy Policy" />

      <Heading isVisuallyHidden={true}>Privacy Policy</Heading>

      <Section hasNoTag={true}>
        <PrivacyPolicyEnPage footer={footer} />
      </Section>
    </>
  );
};

export default PrivacyPolicyEnPageContainer;
