import React from 'react';

import envConfig from '../../config/env';

const useGoogleAnalyticsOptOut = () => {
  const disableString = `ga-disable-${envConfig.googleAnalyticsId}`;

  const [isGoogleAnalyticsOptOutSet, setIsGoogleAnalyticsOptOutSet] = React.useState<boolean>(false);

  const handleGoogleAnalyticsOptOut = () => {
    if (typeof window !== 'undefined') {
      (window as any)[disableString] = true;

      if (typeof document !== 'undefined') {
        document.cookie = `${disableString}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
      }

      setIsGoogleAnalyticsOptOutSet(true);
    }
  };

  return { handleGoogleAnalyticsOptOut, isGoogleAnalyticsOptOutSet };
};

export default useGoogleAnalyticsOptOut;
