import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLParagraphElement>;

const StaticPageAddress = ({ children, ...rest }: Props) => {
  return <S.Address {...rest}>{children}</S.Address>;
};

export default StaticPageAddress;
