import styled from '../../../utils/styling/styled';

import Link from '../../Link';
import styleUtils from '../../../utils/styling/styleUtils';

export const LanguageLink = styled(Link)`
  ${styleUtils.margin.vertical.bottom};

  ${styleUtils.font.weight.bold};

  color: ${props => props.theme.color.green};
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;

  &:last-of-type {
    ${styleUtils.margin.vertical.bottomNone};
  }
`;
